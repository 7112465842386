// Font Family
$oldstan: 'Old Standard TT', serif;
$archivo: 'Archivo', sans-serif;
$roboto: 'Roboto', sans-serif;
$lora: 'Lora', serif;
$mont: 'Montserrat', serif;
$tajwl: 'Tajawal', sans-serif;

// Colors ---------------
$primary-color: #FFFFFF;
$secondary-color: #215C4F;
$text-color: #BA945C;
$heading-color: #222;
$main-bg: #f8f8f8;
$black: #111;
$white: #fff;
$nile-blue: #183254;
$mine-shaft:#303030;
$eden:#11595c;
$para-color: #424242;
$menu-color: #67AC9D;
$headingtwo-color: #E3B775;
$menuthree-color: #FF6E6E;

$light-bg-color: rgba(186, 148, 92, 0.096);

// Responsive Variables
$xxl: '(min-width: 1600px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: '(max-width: 767px)';
$lsm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xsm: '(max-width: 575px)';
$tiny: '(max-width: 399px)';

//spacing
$v-sp-lg: 2rem;
$v-sp-sm: 1rem;


//font size

$heading-size: 29px;
$subheading-size: 16px;