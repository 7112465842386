.tab-panel {
    margin: 50px 35px;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    // border-radius: 0px 10px 10px 10px;
    @media #{$sm} {
        margin-bottom: 1rem;
        margin-left: 15px;
        margin-right: 15px;
    }
    .tab-content{
        color: $secondary-color;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 0px 10px 10px 10px;
    }
}

.tab-panel-properties {
    .tab-content{
        color: $secondary-color;
        .tab-pane{
            .widget.widget-sidebar-search-wrap{
                padding: 30px 30px 35px 30px;
                background: #fff;
                border-radius: 0 10px 10px 10px;
                .search-column{
                    border-right: 0.5px solid #C4C4C4;
                    &:nth-last-child(1){
                        border: none;
                    }
                    .title {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .btn.purpose{
                        @media #{$sm} {
                            margin-left: 0;
                        }
                        font-weight: 300;
                        background-color: $primary-color;
                        color: $para-color;
                        width: calc(50% - 10px);
                        border: 1px solid #E5E5E5;
                        &:first-child{
                            margin-right: 20px;
                        }
                        &.active, &:focus, &:hover{
                            color: $primary-color;
                            background-color: $secondary-color;
                        }
                    }
                    .btn.purposecomm{
                        @media #{$sm} {
                            margin-left: 0;
                        }
                        font-weight: 300;
                        background-color: brown;
                        color: $para-color;
                        width: calc(50% - 10px);
                        border: 1px solid #E5E5E5;
                        &:first-child{
                            margin-right: 20px;
                        }
                        &.active, &:focus, &:hover{
                            color: $primary-color;
                            background-color: brown;
                        }
                    }
                    .btn.purposeindus{
                        @media #{$sm} {
                            margin-left: 0;
                        }
                        font-weight: 300;
                        background-color: pink;
                        color: $para-color;
                        width: calc(50% - 10px);
                        border: 1px solid #E5E5E5;
                        &:first-child{
                            margin-right: 20px;
                        }
                        &.active, &:focus, &:hover{
                            color: $primary-color;
                            background-color:pink;
                        }
                    }
                    .rld-single-select{
                        color: $para-color;
                    }
                    .small-slider{
                        width: 100%;
                        align-self: center;
                    }
                }
            }
        }
    }
    .rld-banner-tab{
        .nav-item {
            a.active{
                color: $primary-color;
                background-color: $secondary-color;
            }
            a.disabled{
                background: #E5E5E5;
                color: $para-color;
            }
        }

        @media #{$sm} {
            flex-wrap: nowrap;
        }
       .nav-item{
            @media #{$sm} {
               width: 100%;
               text-align: center;
            }
        }
    }
}

.rld-single-select .single-select{

height: auto;
}

.r-single-select {
    line-height: 34px;
    width: 100%;
    padding: 0 34px 0 20px;
    border: 1px solid #d4d4d4;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 4px;
  
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    height: 42px;
    line-height: 40px;
    outline: 0;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    // width: auto;
  }

  @media screen and (max-width: 767px) {
    .r-single-select{
        margin-bottom: 1rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .r-single-select{
        margin-bottom: 1rem;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .r-single-select{
        margin-bottom: 1rem;
    }
  }